import Splide from '@splidejs/splide';
import lottie from 'lottie-web';
import { Video } from '@splidejs/splide-extension-video';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import { AutoHeightSync, FilterMobileSlides } from './splider_extensions';
import { Fancybox } from "@fancyapps/ui";
import intlTelInput from "intl-tel-input";
import noUiSlider from 'nouislider';
import tippy from 'tippy.js';
import AirDatepicker from 'air-datepicker';

window.phoneWidgets = {};

if (!window.App) {
  window.App = {};
}

$.extend(App, {
  init: function () {
    this.initSlider()
    this.initFancybox();
    this.initModals();
    this.initLottieAnimations();
    this.initRangeSlider();
    this.initFeedBackForm();
    this.initPhoneWidget();
    this.initTooltip();
    this.initDatepicker();
    this.initRealProductEditForm();
    this.initDeliveryTypeForm();
    this.initSocialButtons();
    this.initShare();
    this.initHtmxListeners();
    this.initShowroomModalRefresh();
  },

  initSlider: () => {

    const configs = {
      'order-info-gallery': {
        type: 'fade',
        speed: 800,
        pagination: false,
        arrows: false,
        mediaQuery: 'min',
        breakpoints: {
          1024: {
            arrows: true,
          },
        }
      },
      'order-info-gallery-nav': {
        perPage: 3,
        perMove: 1,
        wheel: true,
        speed: 800,
        arrows: false,
        pagination: false,
        isNavigation: true,
        focus: 'center',
        gap: '1rem',
        padding: {right: '15%'},
        mediaQuery: 'min',
        breakpoints: {
          640: {
            perPage: 4,
          },
          768: {
            padding: {right: '0'},
          },
          1024: {
            perPage: 7,
            perMove: 5,
          },
          1440: {
            perPage: 10,
          }
        }
      },
      'real-product-gallery': {
        type: 'fade',
        speed: 800,
        pagination: false,
        arrows: false,
        mediaQuery: 'min',
        breakpoints: {
          1024: {
            arrows: true,
          },
        }
      },
      'real-product-gallery-nav': {
        perPage: 3,
        perMove: 1,
        wheel: true,
        speed: 800,
        arrows: false,
        pagination: false,
        isNavigation: true,
        gap: '1rem',
        padding: {right: '15%'},
        mediaQuery: 'min',
        breakpoints: {
          640: {
            perPage: 4,
          },
          768: {
            padding: {right: '0'},
            arrows: true,
          },
          1024: {
            perPage: 7,
            perMove: 5,
          },
          1440: {
            perPage: 10,
          }
        }
      },
      'product-details': {
        type: 'fade',
        speed: 1000,
        pagination: false,
        arrows: true,
        mediaQuery: 'min',
        video: {
          autoplay: true,
          mute: true,
          loop: true,
        },
        breakpoints: {
          768: {
            arrows: true,
          },
        }
      },
      'product-details-nav': {
        perPage: 4,
        perMove: 1,
        speed: 1000,
        arrows: true,
        pagination: false,
        isNavigation: true,
        trimSpace: 'move',
        gap: '0.5rem',
        height: 'auto',
        mediaQuery: 'min',
        breakpoints: {
          640: {
            perPage: 5,
          },
          768: {
            perPage: 6,
            gap: '1rem',
            height: '628px',
            direction: 'ttb',
            arrows: false,
          },
          1024: {
            height: 'auto',
            direction: 'ltr',
          },
          1440: {
            height: '854px',
            direction: 'ttb',
          }
        }
      },
      'product-list': {
        perPage: 4,
        perMove: 1,
        pagination: false,
        gap: '1rem',
        padding: {right: '8.75rem'},
        speed: 1500,
        breakpoints: {
          1439: {
            perPage: 3,
          },
          1023: {
            perPage: 2,
            padding: {right: 'calc(100vw - 767px)'},
          },
          767: {
            padding: {right: '0'},
          },

        },
      },
      'reviews': {
        perPage: 3,
        perMove: 1,
        pagination: false,
        gap: '1rem',
        padding: {right: '8.75rem'},
        speed: 1500,
        breakpoints: {
          1439: {
            perPage: 2,
            padding: {right: 'calc(100vw - 1170px)'},
          },
          1023: {
            padding: {right: 'calc(100vw - 767px)'},
          },
          767: {
            perPage: 1,
            padding: {right: 'calc(100vw - 451px)'},
          },
          451: {
            padding: {right: '0'},
          },
        },
      },
      'custom-projects': {
        perPage: 3,
        perMove: 1,
        pagination: false,
        gap: '1rem',
        speed: 1500,
        breakpoints: {
          1439: {
            perPage: 2,
          },
          767: {
            perPage: 1,
          },
        },
      },
      'hero-slider': {
        type: 'fade',
        speed: 2000,
        arrows: false,
        rewind: true,
        autoplay: true,
        interval: 6000,
        pauseOnHover: false,
        pauseOnFocus: false,
        drag: true,
        swipe: true,
      },
      'our-clients': {
        type: 'loop',
        pagination: false,
        arrows: false,
        autoWidth: true,
        drag: false,
        gap: '2rem',
        autoScroll: {
          speed: 0.8,
          pauseOnHover: false,
          pauseOnFocus: false,
        },
      },
      'popular-articles': {
        perPage: 1,
        perMove: 1,
        pagination: false,
        gap: '1rem',
        speed: 1500,
        mediaQuery: 'min',
        breakpoints: {
          768: {
            perPage: 2,
          },
          1024: {
            perPage: 1,
          },
          1440: {
            perPage: 2,
          },
        },
      }
    }

    let sliders = new Map();

    $('.j_init-slider').each((_, el) => {
      const configName = $(el).data('config-name');
      const config = configs[configName];
      if(config) {
        const splide = new Splide(el, config);
        sliders[configName] = splide;

        if (['product-details', 'product-details-nav'].every(item => item in sliders)) {
          sliders['product-details'].sync(sliders['product-details-nav']);
          delete sliders['product-details'];
          delete sliders['product-details-nav'];
        }

        if (['real-product-gallery', 'real-product-gallery-nav'].every(item => item in sliders)) {
          sliders['real-product-gallery-nav'].sync(sliders['real-product-gallery']);
          delete sliders['real-product-gallery'];
          delete sliders['real-product-gallery-nav'];
        }

        if (['order-info-gallery', 'order-info-gallery-nav'].every(item => item in sliders)) {
          sliders['order-info-gallery-nav'].sync(sliders['order-info-gallery']);
          delete sliders['order-info-gallery'];
          delete sliders['order-info-gallery-nav'];
        }

        splide.mount({
          ...(splide.options.autoScroll ? { AutoScroll } : {}),
          ...(configName === 'product-details-nav' ? { AutoHeightSync } : {}),
          ...(configName === 'hero-slider' ? { FilterMobileSlides } : {}),
          ...(configName === 'product-details' ? { Video } : {}),
        });
      }
    });
  },

  initFancybox() {
    Fancybox.bind("[data-fancybox]", {
      Thumbs: false,
      Toolbar: {
        display: {
          left: [],
          middle: [],
          right: ["close"],
        },
      },
    });
  },

  initModals: () => {
    App.modalWindow = new HystModal({
      linkAttributeName: "data-open-modal",
      waitTransitions: true,
    });
  },

  initLottieAnimations: () => {
    $('.j_animation-item').each((_, container) => {
      lottie.loadAnimation({
        container: container,
        renderer: 'svg',
        loop: container.dataset.lottieLoop === 'true',
        autoplay: container.dataset.lottieAutoplay === 'true',
        path: container.dataset.lottiePath
      });
    });

    $('body').on('click', '.j_animation-trigger', App.startLoader);
    $('body').on('htmx:configRequest', '#order-form', App.startLoader);
    $('body').on('htmx:afterSwap', '#order-form', App.stopLoader);
  },

  startLoader: () => {
    $('.j_loader-animation').removeClass('hidden');
    $('body').style = 'overflow: hidden';
  },

  stopLoader: () => {
    $('.j_loader-animation').addClass('hidden');
    $('body').style = 'overflow: auto';
  },

  initRangeSlider: () => {
    $('.j_price-range').each((_, el) => {
      const priceData = $(el).data();
      const slider = $(el).find('.j_price-range-slider')[0];
      const inputs = $(el).find('.j_price-range-input').toArray();

      if (!priceData || Object.values(priceData).some(value => value === "None")) {
        return
      }


      noUiSlider.create(slider, {
        start: [priceData.priceMin, priceData.priceMax],
        step: 5,
        connect: true,
        format: {
          from: formattedValue => Number(formattedValue),
          to: numericValue => Math.round(numericValue),
        },
        range: {
          'min': priceData.min,
          'max': priceData.max
        }
      });

      slider.noUiSlider.on('update', (values, handle) => {
        inputs[handle].value = values[handle];
      });


      inputs.forEach((input, handle) => {

        input.addEventListener('change', (evt) => {
          slider.noUiSlider.setHandle(handle, evt.currentTarget.value);
        });

        input.addEventListener('keydown', (evt) => {
          const value = Number(slider.noUiSlider.get()[handle]);
          const step = slider.noUiSlider.options.step;

          if (evt.key === "Enter") {
            slider.noUiSlider.setHandle(handle, evt.currentTarget.value);
          } else if (evt.key === "ArrowUp" || evt.key === "ArrowDown") {
            const position = value + (evt.key === "ArrowUp" ? step : -step);
            slider.noUiSlider.setHandle(handle, position);
          }
        });
      });
    });
  },

  initFeedBackForm: () => {
    var feedback_form_container = $('.j_feedback_form_container');
    if (feedback_form_container.length) {
      $(feedback_form_container, '.j_feedback_form').on('g-verified', function (e) {
        e.preventDefault();
        var feedback_form = $('.j_feedback_form');
        $(feedback_form).find('input[type=submit]').attr('disabled', 'disabled');
        var post_data = feedback_form.serialize() + "&feedbackformsubmit=true";
        $.post(feedback_form.attr("action"), post_data, function (data) {
          feedback_form_container.html(data);
          $('.spm_cls').hide();
        });
      });
    }
  },


  initPhoneWidget: () => {
    $('.j_phone-number').each((_, el) => {
      const key = $(el).attr('id');

      if (window.phoneWidgets[key]) {
        window.phoneWidgets[key].destroy();
        delete window.phoneWidgets[key];
        el.closest('form').removeEventListener('submit', submitFormHandler);
      }

      const itiObject = intlTelInput(el, {
        initialCountry: "gb",
        autoPlaceholder: false,
        loadUtils: () => import("intl-tel-input/utils"),
      });

      $('<input>', {
        type: 'checkbox',
        name: 'is_valid_number',
        class: 'sr-only',
      }).insertAfter(el);

      window.phoneWidgets[key] = itiObject;

      el.closest('form').addEventListener('submit', submitFormHandler, true);
    });

    function submitFormHandler(evt) {
      const el = evt.target.querySelector('.j_phone-number');
      const itiObject = window.phoneWidgets[el.id];
      itiObject.telInput.value = itiObject.getNumber();
      el.nextElementSibling.checked = itiObject.isValidNumber();
    }
  },

  initTooltip: () => {
    tippy("[data-tooltip]", {
      content(reference) {
        const id = reference.getAttribute('data-template');
        const template = document.getElementById(id);
        return template.innerHTML;
      },
      allowHTML: true,
      arrow: false,
      placement: 'top',
      theme: 'default',
      duration: 0,
      touch: false,
    });
  },

  initDatepicker: () => {
    $('.j_datepicker').each((_, el) => App.datepickerInit(el));
  },

  datepickerInit: (el) => {
    const today = new Date();
    const options = {
      selectedDates: el.value ? [new Date(el.value)] : [new Date().setDate(today.getDate() + 1)],
      minDate: today,
      maxDate: new Date(new Date().setMonth(today.getMonth() + 6)),
      selectOtherMonths: false,
      autoClose: true,
      classes: 'datepicker-showroom',
      position: 'bottom center',
      dateFormat: 'yyyy-MM-dd',
      container: el.closest('.field-wrapper'),
      navTitles: {
        days: 'MMMM yyyy',
      },
      disableNavButtons: true,
    };

    new AirDatepicker(el, options);
  },

  initRealProductEditForm: () => {
    $(document).on('submit', '.j_edit_gallery_image_form', e => {
        e.preventDefault()
        let form = $(e.currentTarget)
        let photo_id = form.data('id')
        $.post(
            form.attr('action'),
            form.serialize()
        )
        .done(response => {
            $(`li[data-photo-id=${photo_id}]`).replaceWith(response)
            Fancybox.close();
        })
    })
  },

  woodSampleHandler: () => {
    return {
      activeItems: [],

      init() {
        const checkedCheckboxes = document.querySelectorAll('input.wood-sample-checkbox[type="checkbox"]:checked');
        checkedCheckboxes.forEach(element => {
          if (this.activeItems.length >= 4) {
            element.checked = false;
            return;
          }
          this.activeItems.push({
            id: element.id,
            title: element.dataset.title,
            image_url: element.dataset.imageUrl,
            image_url_retina: element.dataset.imageUrlRetina,
          });
        });
      },

      toggleItem(element) {
        const item = {
          id: element.id,
          title: element.dataset.title,
          image_url: element.dataset.imageUrl,
          image_url_retina: element.dataset.imageUrlRetina,
        };

        const isActive = this.activeItems.some(activeItem => activeItem.id === item.id);

        if (!isActive && this.activeItems.length >= 4) {
          App.modalWindow.open('#wood-sample-limit-modal');
          element.checked = false; // Отменяем активацию чекбокса
          return;
        }

        if (isActive) {
          this.activeItems = this.activeItems.filter(activeItem => activeItem.id !== item.id);
        } else {
          this.activeItems.push(item);
        }
      },

      removeItem(activeItem) {
        this.activeItems = this.activeItems.filter(item => item.id !== activeItem.id);
        const checkbox = document.querySelector(`input.wood-sample-checkbox[id="${activeItem.id}"]`);
        if (checkbox) checkbox.checked = false;
      }
    };
  },


  initDeliveryTypeForm: () => {
    htmx.on("htmx:afterSwap", function(evt) {
        if ($(evt.target).data('close-form')) {
            $('.j_off-canvas-close').trigger('click')
        }
        if (evt.target.id && evt.target.id === 'product-form'){
          App.initTooltip();
        }
    })
  },

  redirectToPriceRangeUrl: (el) => {
    App.startLoader();
    const values = [...el.querySelectorAll('.j_price-range-input')].map(input => input.value);
    const min = Math.min(...values);
    const max = Math.max(...values);
    const url = el.getAttribute('data-url').replace('#min', min).replace('#max', max);
    (min > 0 && max > 0) ? document.location.href = url : document.location.reload();
  },

  initSocialButtons: () => {
    $('.j_soc-share').on('click', 'a', function (e) {
        var obj = $(e.currentTarget), data = {}
        data.url = window.location.href
        data.title = document.title
        if (Share[obj.data('provider')]){
          Share[obj.data('provider')](data)
          e.preventDefault()
          e.stopPropagation()
        }
    });
  },

  initShare: () => {
      let url;
      let Share = {
          fb: data => {
              url = 'http://www.facebook.com/sharer/sharer.php?'
              url += 'u=' + encodeURIComponent(data.url)
              Share.popup(url)
          },
          tw: data => {
              url = 'http://twitter.com/share?'
              url += 'text=' + encodeURIComponent(data.title)
              url += '&url=' + encodeURIComponent(data.url)
              url += '&counturl=' + encodeURIComponent(data.url)
              Share.popup(url)
          },
          gp: data => {
              url = 'https://plus.google.com/share?'
              url += '&url=' + encodeURIComponent(data.url)
              Share.popup(url)
          },
          popup: url => {
              window.open(url, '', 'toolbar=0,status=0,width=626,height=436')
          }
      }
      window.Share = Share;
  },

  loginFormOnSubmit: () => {
    const customEvent = new Event('loginFormSubmit', { 'bubbles': false });
    document.querySelector('body').dispatchEvent(customEvent)
  },

  initHtmxListeners: () => {
    document.body.addEventListener('htmx:afterSettle', (evt) => {
      if (evt.detail.target.id === 'similar-products') {
        App.initSlider();
      } else if (evt.detail.target.id === 'showroom-form') {
        App.initPhoneWidget();
        App.initDatepicker();
      }
    });
  },

  initShowroomModalRefresh: () => {
    $('.j_showroom-modal').on('click', function() {
      const customEvent = new Event('refreshShowroomModal', { 'bubbles': false });
      document.querySelector('body').dispatchEvent(customEvent)
    });
  }

});

$(function () {
  App.init ();
});
